import apiClient from './apiService';
import store from '@/store';
import { User, Profile } from '@/interfaces/User';
import studentsService from './studentsService';

class AuthService {

    // Gets the user and student profile from the server.
    async getProfile(): Promise<Profile | null> {
        try {
            const response = await apiClient.get<ApiResponse<User>>('/user');
            const user = response.data.data;

            const studentInfoResponse = await studentsService.getStudentProfile(user.id);
            const student = studentInfoResponse.data;

            localStorage.setItem('me', JSON.stringify({ user, student }));
            localStorage.setItem('return_url', user.return_url); // As requested , to make sure the user is logged out to learning platform url.

            store.dispatch('currentUser/updateUser', user);
            store.dispatch('currentUser/updateStudentProfile', student);
            return { user, student };
        } catch (error) {
            store.dispatch('currentUser/updateUser', null);
            store.dispatch('currentUser/updateStudentProfile', null);
            localStorage.removeItem('me'); // Clear the user profile from the local storage
            localStorage.removeItem('return_url'); // Clear the logout url from the local storage
            return null;
        }
    }

    async logout(): Promise<void> {
        const response = await apiClient.post('/logout');
        if (response.status === 200) {
            window.location.href = localStorage.getItem('return_url') || '/login';

            // Margin to clear the session and state after the redirect has started
            setTimeout(() => {
                store.dispatch('currentUser/updateUser', null);
                store.dispatch('currentUser/updateStudentProfile', null);
                localStorage.removeItem('me'); // Clear the user profile from the local storage
                localStorage.removeItem('return_url'); // Clear the logout url from the local storage
                sessionStorage.clear();
            }, 500); // 500ms

        } else {

            window.location.href = localStorage.getItem('return_url') || '/login';

            store.dispatch('currentUser/updateUser', null);
            store.dispatch('currentUser/updateStudentProfile', null);
            localStorage.removeItem('me'); // Clear the user profile from the local storage
            localStorage.removeItem('return_url'); // Clear the logout url from the local storage
            sessionStorage.clear();
        }
    }

    isAuthenticated(): boolean {
        return !!store.getters['currentUser/isAuthenticated'];
    }
}

export default new AuthService();