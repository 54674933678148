import apiClient from './apiService'
import { Student } from '@/interfaces/Student'
import { ActiveExam, AssignedExam } from '@/interfaces/Exam'

export default {

    // Student Profile.

    async getStudentProfile(userId: number): Promise<ApiResponse<Student>> {
        const response = await apiClient.get<ApiResponse<Student>>(`/students/${userId}/profile`)
        return response.data;
    },

    // Exams Landing Page.

    async getStudentExams(studentId: number): Promise<ApiResponse<AssignedExam[]>> { // Assigned Exams
        const response = await apiClient.get<ApiResponse<AssignedExam[]>>(`/students/${studentId}/exams`)
        return response.data
    },

    async getStudentMockExams(studentId: number): Promise<ApiResponse<AssignedExam[]>> { // Assigned Mock Exams.
        const response = await apiClient.get<ApiResponse<AssignedExam[]>>(`/students/${studentId}/exams/mock`)
        return response.data;
    },

    // Part Of Starting Exam.
    async startExam(studentId: number, examId: number): Promise<ApiResponse<AssignedExam>> {
        const response = await apiClient.post<ApiResponse<AssignedExam>>(`/students/${studentId}/exam/start`, { exam_id: examId })
        return response.data
    },

    // While Taking Exam.

    async getActiveExam(studentId: number, examId: number): Promise<ActiveExam> {
        const response = await apiClient.get<{ status: string; message: string; data: ActiveExam }>(`/students/${studentId}/exam/${examId}/active`)
        return response.data.data
    },

    async getExamResponses(studentId: number, examId: number): Promise<ApiResponse<any[]>> {
        const response = await apiClient.post<ApiResponse<any[]>>(`/students/${studentId}/exam/response`, { exam_id: examId })
        return response.data;
    },

    async syncExam(studentId: number, examId: number, responses: any, remaining_duration: number | null): Promise<ApiResponse<AssignedExam>> {
        const response = await apiClient.post<ApiResponse<AssignedExam>>(`/students/${studentId}/exam/sync`, { exam_id: examId, responses: responses, remaining_duration: remaining_duration })
        return response.data;
    },

    async submitExam(studentId: number, examId: number, responses: any, remaining_duration: number | null, force_submit: boolean = false): Promise<ApiResponse<AssignedExam>> {
        const params = { exam_id: examId, responses: responses, remaining_duration: remaining_duration, force_submit: force_submit }
        const response = await apiClient.post<ApiResponse<AssignedExam>>(`/students/${studentId}/exam/submit`, params)
        return response.data;
    }
}