import { computed } from 'vue';
import { useStore } from 'vuex';
import authService from "@/services/authService";

export function useCurrentUser() {
  const store = useStore();

  const user = computed(() => store.getters['currentUser/currentUser']);
  const studentProfile = computed(() => store.getters['currentUser/studentProfile']);
  const isAuthenticated = computed(() => store.getters['currentUser/isAuthenticated']);

  const logoutUser = async () => {
    await authService.logout();
  };

  return {
    user,
    studentProfile,
    isAuthenticated,
    logoutUser
  };
}

export default useCurrentUser;
