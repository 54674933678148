import { Module } from 'vuex';
import { User } from '@/interfaces/User';
import { Student } from '@/interfaces/Student';

interface CurrentUserState {
    currentUser: User | null;
    studentProfile: Student | null;
}

const currentUserState: Module<CurrentUserState, any> = {
    namespaced: true,
    state: {
        currentUser: null,
        studentProfile: null
    },
    mutations: {
        setCurrentUser(state, user: User | null) {
            state.currentUser = user;
        },
        setStudentProfile(state, student: Student | null) {
            state.studentProfile = student;
        },
    },
    actions: {
        updateUser({ commit }, user: User | null) {
            commit('setCurrentUser', user);
        },
        updateStudentProfile({ commit }, student: Student | null) {
            commit('setStudentProfile', student);
        },
    },
    getters: {
        currentUser: (state) => state.currentUser,
        studentProfile: (state) => state.studentProfile,
        isAuthenticated: (state) => !!state.currentUser,
        /**
         * Check if the user has a specific permission
         */
        can: (state) => (permission: string) => {
            return state.currentUser?.permissions.some(p => p.permission === permission) || false;
        },
        /**
         * Check if the user has a specific role
         */
        hasRole: (state) => (role: string) => {
            return state.currentUser?.roles.some(r => r.role === role) || false;
        }
    }
};

export default currentUserState;
